.game-night-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.65);
  display: flex;
  justify-content: center;
  align-items: flex-start;
  z-index: 1000;
  padding: 10px;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  backdrop-filter: blur(5px);
  opacity: 0;
  transition: opacity 0.4s ease-out, backdrop-filter 0.4s ease-out;
}

.game-night-modal-overlay.active {
  opacity: 1;
}

.game-night-modal {
  background: linear-gradient(145deg, #FFF5F5, #FFFFFF);
  padding: 0;
  border-radius: 24px;
  width: 90%;
  max-width: 500px;
  position: relative;
  box-shadow: 0 20px 40px rgba(0, 0, 0, 0.2), 0 10px 20px rgba(0, 0, 0, 0.1);
  margin: 10px auto;
  transform: translateY(30px) scale(0.95);
  opacity: 0;
  transition: transform 0.5s cubic-bezier(0.175, 0.885, 0.32, 1.275), opacity 0.4s ease-out;
  overflow: hidden;
  max-height: calc(100vh - 40px);
  display: flex;
  flex-direction: column;
}

.game-night-modal.active {
  transform: translateY(0) scale(1);
  opacity: 1;
}

.modal-header {
  padding: 0 0 0.75rem 0;
  text-align: center;
  position: relative;
}

.preview-image {
  width: 100%;
  height: auto;
  object-fit: cover;
  border-radius: 24px 24px 0 0;
  margin-bottom: 0.75rem;
  transition: all 0.5s ease;
  filter: brightness(1.02);
  max-height: 140px;
}

.modal-title {
  margin: 0 0 0.25rem;
  font-size: 1.6rem;
  color: #000;
  text-align: center;
  line-height: 1.2;
  font-weight: 600;
  letter-spacing: -0.5px;
  padding: 0 1.5rem;
}

.modal-subtitle {
  margin: 0 0 0.25rem;
  color: #555;
  text-align: center;
  font-size: 1rem;
  line-height: 1.4;
  padding: 0 1.5rem;
  letter-spacing: 0.2px;
  font-weight: 400;
}

.close-button {
  position: absolute;
  top: 1rem;
  right: 1rem;
  background: rgba(255, 255, 255, 0.9);
  border: none;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.8rem;
  cursor: pointer;
  color: #333;
  line-height: 1;
  transition: all 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  z-index: 10;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
}

.close-button:hover {
  color: #000;
  background: rgba(255, 255, 255, 1);
  transform: rotate(90deg) scale(1.1);
  box-shadow: 0 6px 15px rgba(0, 0, 0, 0.2);
}

.signup-form {
  padding: 0 1.5rem 1rem;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  flex: 1;
}

@media (max-width: 480px) {
  .game-night-modal {
    width: 95%;
    margin: 10px auto;
  }

  .modal-title {
    font-size: 1.4rem;
    padding: 0 1rem;
  }

  .modal-subtitle {
    font-size: 0.9rem;
    padding: 0 1rem;
  }

  .preview-image {
    max-height: 120px;
  }

  .form-group {
    margin-bottom: 0.9rem;
  }

  .form-group input {
    padding: 0.7rem 0.9rem;
    font-size: 0.95rem;
    border-radius: 12px;
  }

  .submit-button {
    padding: 0.8rem 1.25rem;
    font-size: 1rem;
    margin-top: 1rem;
  }

  .close-button {
    top: 0.75rem;
    right: 0.75rem;
    width: 36px;
    height: 36px;
    font-size: 1.5rem;
  }
  
  .form-footer {
    margin-top: 0.75rem;
    padding-top: 0.5rem;
  }
}

.form-group {
  margin-bottom: 1rem;
  position: relative;
  transition: all 0.3s ease;
}

.form-label {
  position: absolute;
  left: 1rem;
  top: 0.85rem;
  color: #999;
  font-size: 1rem;
  pointer-events: none;
  transition: all 0.25s ease;
  transform-origin: left top;
  opacity: 0;
}

.form-group.active .form-label,
.form-group.filled .form-label {
  transform: translateY(-1.8rem) scale(0.85);
  color: #333;
  opacity: 1;
}

.form-group input {
  width: 100%;
  padding: 0.85rem 1rem;
  border: 2px solid #eee;
  border-radius: 16px;
  font-size: 1rem;
  transition: all 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  outline: none;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.03);
  background-color: rgba(255, 255, 255, 0.8);
}

.form-group.active input {
  border-color: #000;
  box-shadow: 0 6px 15px rgba(0, 0, 0, 0.08);
  transform: translateY(-2px);
  background-color: #fff;
}

.form-group input:focus {
  border-color: #000;
}

.submit-button {
  width: 100%;
  padding: 0.9rem 1.5rem;
  background: linear-gradient(135deg, #000000, #333333);
  color: white;
  border: none;
  border-radius: 30px;
  font-size: 1.05rem;
  font-weight: 500;
  letter-spacing: 0.5px;
  cursor: pointer;
  transition: all 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  margin-top: 1rem;
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.15);
  position: relative;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}

.button-text {
  margin-right: 10px;
  position: relative;
  z-index: 2;
  transition: all 0.3s ease;
}

.button-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  z-index: 2;
  transition: all 0.3s ease;
}

.submit-button:hover:not(:disabled) {
  background: linear-gradient(135deg, #333333, #000000);
  transform: translateY(-3px);
  box-shadow: 0 12px 25px rgba(0, 0, 0, 0.2);
}

.submit-button:hover:not(:disabled) .button-text {
  transform: translateX(-5px);
}

.submit-button:hover:not(:disabled) .button-icon {
  transform: translateX(5px);
}

.submit-button::after {
  content: '';
  position: absolute;
  top: -50%;
  left: -50%;
  width: 200%;
  height: 200%;
  background: radial-gradient(circle, rgba(255,255,255,0.3) 0%, rgba(255,255,255,0) 70%);
  opacity: 0;
  transform: scale(0.5);
  transition: transform 0.5s, opacity 0.5s;
  z-index: 1;
}

.submit-button:hover::after {
  opacity: 1;
  transform: scale(1);
}

.submit-button:disabled {
  opacity: 0.7;
  cursor: not-allowed;
}

.submit-button.submitting {
  background: linear-gradient(135deg, #333333, #000000);
}

.spinner {
  animation: rotate 2s linear infinite;
  width: 20px;
  height: 20px;
}

.spinner .path {
  stroke: white;
  stroke-linecap: round;
  animation: dash 1.5s ease-in-out infinite;
}

@keyframes rotate {
  100% {
    transform: rotate(360deg);
  }
}

@keyframes dash {
  0% {
    stroke-dasharray: 1, 150;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -35;
  }
  100% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -124;
  }
}

.status-message {
  margin-top: 1.5rem;
  padding: 1rem;
  border-radius: 16px;
  text-align: center;
  animation: fadeIn 0.4s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  box-shadow: 0 6px 15px rgba(0, 0, 0, 0.08);
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: center;
}

.status-icon {
  margin-right: 10px;
  flex-shrink: 0;
}

.status-message.success {
  background-color: rgba(46, 125, 50, 0.1);
  color: #2e7d32;
  border: 1px solid rgba(46, 125, 50, 0.2);
  border-left: 3px solid #2e7d32;
}

.status-message.error {
  background-color: rgba(198, 40, 40, 0.1);
  color: #c62828;
  border: 1px solid rgba(198, 40, 40, 0.2);
  border-left: 3px solid #c62828;
}

.form-footer {
  margin-top: 1rem;
  text-align: center;
  font-size: 0.85rem;
  color: #666;
  line-height: 1.4;
  border-top: 1px solid rgba(0,0,0,0.05);
  padding-top: 0.75rem;
}

.form-footer p {
  margin: 0;
  font-style: italic;
}

.arrow {
  transition: all 0.3s ease;
}

@media (max-width: 480px) {
  .modal-title {
    font-size: 1.6rem;
  }
  
  .signup-form {
    padding: 0 1.5rem 1.5rem;
  }
  
  .form-group {
    margin-bottom: 1.2rem;
  }
}

@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}

@keyframes slideIn {
  from {
    opacity: 0;
    transform: translateY(30px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes spin {
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
} 