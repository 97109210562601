@font-face {
  font-family: 'Telugu MN';
  src: url('./telugu-mn.ttf') format('truetype');
}

body {
  margin: 0;
  padding: 0;
  font-family: 'Telugu MN', Georgia, 'Times New Roman', Times, serif;
  min-height: 100vh;
  background-color: #000000;
  min-height: -webkit-fill-available;
  padding: env(safe-area-inset-top) env(safe-area-inset-right) env(safe-area-inset-bottom) env(safe-area-inset-left);
  color: #ffffff;
}

.App {
  text-align: center;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  position: relative;
  overflow: hidden;
  padding-top: 60px;
  padding-top: calc(30px + env(safe-area-inset-top));
  padding-bottom: env(safe-area-inset-bottom);
  background-image: url('../public/Events/background.png');
  background-size: 100% auto;
  background-position: top center;
  background-repeat: no-repeat;
  background-attachment: fixed;
  width: 100%;
  height: 100%;
  background-color: #000000;
}

/* Add this new rule */
@supports (padding-top: constant(safe-area-inset-top)) {
  body, .App {
    padding-top: constant(safe-area-inset-top);
    padding-right: constant(safe-area-inset-right);
    padding-bottom: constant(safe-area-inset-bottom);
    padding-left: constant(safe-area-inset-left);
  }
}

.blush {
  position: fixed;
  width: 120px; /* Slightly larger size for more impact */
  height: 120px; /* Slightly larger size for more impact */
  pointer-events: none;
  transform: translate(-50%, -50%);
  transition: 0.2s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  background: radial-gradient(circle, rgba(255,192,203,0.5) 0%, rgba(255,192,203,0.15) 50%, rgba(255,192,203,0) 70%); /* Enhanced pink color */
  mix-blend-mode: multiply;
  border-radius: 50%;
  z-index: 10;
  filter: blur(2px);
}

.blush::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='100' height='100' viewBox='0 0 100 100'%3E%3Cfilter id='noise'%3E%3CfeTurbulence type='fractalNoise' baseFrequency='0.8' numOctaves='4' stitchTiles='stitch'/%3E%3C/filter%3E%3Crect width='100' height='100' filter='url(%23noise)' opacity='0.1'/%3E%3C/svg%3E");
  background-size: 100px 100px;
  opacity: 0.5; /* Reduced opacity */
  border-radius: 50%;
  mix-blend-mode: overlay;
}


.game-title {
  position: absolute;
  top: 20px;
  left: 0;
  margin-bottom: 10px; /* Add this line to reduce space below the title */
  right: 0;
  text-align: center;
  z-index: 2;
}

.game-title h1 {
  font-size: 2.5rem;
  color: #000;
  margin-bottom: 0;
  letter-spacing: 2px;
}

.game-title p {
  font-size: 1rem;
  color: #000;
  margin-top: 0.2rem;
  font-style: italic;
}

.motto-container {
  text-align: center;
  margin-top: 20px;
  margin-bottom: 30px;
  padding: 0 20px;
}

.motto-italian {
  font-family: 'Telugu MN', Georgia, 'Times New Roman', Times, serif;
  font-size: 24px;
  color: #ffffff;
  margin-bottom: 8px;
  font-style: italic;
}

.motto-english {
  font-family: 'Telugu MN', Georgia, 'Times New Roman', Times, serif;
  font-size: 18px;
  color: rgba(255, 255, 255, 0.8);
  font-style: italic;
}

.game-area {
  position: relative;
  width: 80vw;
  height: 70vh;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
  margin-top: 20px;
  margin-bottom: 0px; /* Reduce space below the game area */

}

.question-card {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  transition: all 0.8s cubic-bezier(0.4, 0.0, 0.2, 1);
  width: 220px;
  z-index: 2;
  cursor: pointer;
  opacity: 1;
  will-change: transform, opacity;
  backface-visibility: hidden;
  -webkit-font-smoothing: subpixel-antialiased;
}

.question-card.changing {
  opacity: 0;
  transform: translate(-50%, -50%) scale(0.98);
  pointer-events: none;
}



.question-card.show {
  opacity: 1;
}

.question-card:hover {
  transform: translate(-50%, -50%) scale(1.05);
}

.question-card img {
  width: 100%;
  height: auto;
  border-radius: 15px;
  box-shadow: 0 15px 30px rgba(0, 0, 0, 0.2), 0 5px 15px rgba(0, 0, 0, 0.1);
  transition: all 0.8s cubic-bezier(0.4, 0.0, 0.2, 1);
  filter: brightness(1.02);
  opacity: 1;
  will-change: opacity, transform;
}

.question-card:active img {
  opacity: 0.8;
  transform: scale(0.95);
}

.answer-cards {
  position: absolute;
  width: 100%;
  height: 100%;
}

.answer-card {
  position: absolute;
  width: 180px;
  opacity: 1;
  transition: all 0.8s cubic-bezier(0.4, 0.0, 0.2, 1);
  cursor: pointer;
  will-change: transform, opacity;
  backface-visibility: hidden;
  -webkit-font-smoothing: subpixel-antialiased;
}

.answer-card.changing {
  opacity: 0;
  transform: scale(0.98);
  pointer-events: none;
}

.answer-card.show {
  opacity: 1;
}

.answer-card:hover {
  transform: scale(1.1) translateY(-10px) rotate(0deg) !important;
  z-index: 3;
}

.answer-card:nth-child(1) {
  top: 10%;
  left: 10%;
  transform: rotate(15deg) translateY(50px);
}

.answer-card:nth-child(1).show {
  transform: rotate(15deg) translateY(0);
}

.answer-card:nth-child(2) {
  top: 10%;
  right: 10%;
  transform: rotate(-15deg) translateY(50px);
}

.answer-card:nth-child(2).show {
  transform: rotate(-15deg) translateY(0);
}

.answer-card:nth-child(3) {
  bottom: 10%;
  left: 10%;
  transform: rotate(-15deg) translateY(50px);
}

.answer-card:nth-child(3).show {
  transform: rotate(-15deg) translateY(0);
}

.answer-card:nth-child(4) {
  bottom: 10%;
  right: 10%;
  transform: rotate(15deg) translateY(50px);
}

.answer-card:nth-child(4).show {
  transform: rotate(15deg) translateY(0);
}

.question-card img,
.answer-card img {
  width: 100%;
  height: auto;
  border-radius: 10px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
  transition: all 0.3s ease-out;
}

.turn-on-card {
  position: relative;
  display: inline-block;
}

.turn-on-text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #808080;
  font-family: 'Telugu MN', sans-serif;
  font-size: 12px;
  text-align: center;
  width: 90%;
  padding: 5px;
}

.game-explanation {
  max-width: 800px;
  margin-top: -60px; /* Reduce space above the explanation */
  margin-bottom: 0px; /* Reduce space below the explanation */

  padding: 20px;
  text-align: center;
}

.game-explanation h2 {
  font-family: -apple-system, BlinkMacSystemFont, 'Helvetica Neue', sans-serif;
  font-size: 24px;
  line-height: 1.5;
  font-weight: 400;
  max-width: 800px;
  margin: 40px auto;
  padding: 0 20px;
  text-align: center;
  color: #ffffff;
}

.game-explanation p {
  font-size: 1.2rem;
  line-height: 1.6;
  margin-bottom: 15px;
  font-style: normal;
  color: #000;
}

@media (max-width: 768px) {
  .game-area {
    width: 95vw;
    height: 50vh; /* Reduced height */
    margin-bottom: 0;
    padding-bottom: 0;
  }

  .question-card {
    width: 200px;
  }

  .answer-card {
    width: 160px;
  }

  .answer-card:nth-child(1) {
    top: 5%; /* Moved up */
    left: 10%;
  }

  .answer-card:nth-child(2) {
    top: 5%; /* Moved up */
    right: 10%;
  }

  .answer-card:nth-child(3) {
    bottom: 5%; /* Moved up */
    left: 10%;
  }

  .answer-card:nth-child(4) {
    bottom: 5%; /* Moved up */
    right: 10%;
  }

  .buttons-container {
    flex-direction: column;
    width: calc(100% - 40px);
    margin: 0 auto 30px;
  }

  .buttons-container:first-of-type {
    margin-top: -60px; /* Adjusted based on new card positioning */
  }

  .buy-now-button,
  .game-night-button {
    width: 100%;
    max-width: none;
    height: 60px;
    font-size: 1.3rem;
  }

  .game-explanation {
    padding: 10px;
  }

  .game-explanation h2 {
    font-size: 20px;
    padding: 0 15px;
    margin: 30px auto;
  }

  .game-explanation p {
    font-size: 1rem;
  }

  .motto-italian {
    font-size: 20px;
  }
  
  .motto-english {
    font-size: 16px;
  }
}

@media (max-width: 480px) {
  .game-area {
    height: 90vh;
  }

  .question-card {
    width: 180px;
  }

  .answer-card {
    width: 140px;
  }

  .answer-card:nth-child(1) {
    top: 15%;
    left: 5%;
  }

  .answer-card:nth-child(2) {
    top: 15%;
    right: 5%;
  }

  .answer-card:nth-child(3) {
    bottom: 15%;
    left: 5%;
  }

  .answer-card:nth-child(4) {
    bottom: 15%;
    right: 5%;
  }

  .game-title h1 {
    font-size: 2rem;
  }

  .game-title p {
    font-size: 0.8rem;
  }

  .game-explanation h2 {
    font-size: 18px;
    padding: 0 12px;
    margin: 20px auto;
  }

  .motto-italian {
    font-size: 18px;
  }

  .motto-english {
    font-size: 14px;
  }
}

/* Override high-specificity rule for buttons */
button[class*="buy-now-button"],
button[class*="game-night-button"],
a[class*="buy-now-button"],
a[class*="game-night-button"] {
  background: rgba(0, 0, 0, 0.85) !important;
  background-color: rgba(0, 0, 0, 0.85) !important;
  color: #ffffff !important;
  border: none !important;
  border-radius: 8px !important;
}

button[class*="buy-now-button"] span,
button[class*="game-night-button"] span,
a[class*="buy-now-button"] span,
a[class*="game-night-button"] span {
  color: #ffffff !important;
}

button[class*="buy-now-button"]:hover,
button[class*="game-night-button"]:hover,
a[class*="buy-now-button"]:hover,
a[class*="game-night-button"]:hover {
  background: rgba(0, 0, 0, 0.95) !important;
  background-color: rgba(0, 0, 0, 0.95) !important;
}

.buy-now-button,
.game-night-button,
.modal-buy-button,
button.buy-now-button,
button.game-night-button,
button.modal-buy-button {
  width: 100%;
  max-width: 300px;
  height: 50px;
  padding: 0 25px;
  font-family: -apple-system, BlinkMacSystemFont, 'Helvetica Neue', sans-serif;
  font-size: 1rem;
  font-weight: normal;
  border-radius: 8px !important;
  cursor: pointer;
  text-transform: none;
  letter-spacing: 1px;
  transition: all 0.3s ease;
  display: flex;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
  background: rgba(0, 0, 0, 0.85) !important;
  background-color: rgba(0, 0, 0, 0.85) !important;
  color: #ffffff !important;
  border: none !important;
}

.social-links {
  text-align: center;
  margin: 30px 0;
}

/* Add responsive adjustments for very small screens */
@media screen and (max-width: 380px) {
  .game-area {
    height: 85vh;
    margin-top: 10px;
  }

  .question-card {
    width: 160px;
  }

  .answer-card {
    width: 130px;
  }

  .answer-card:nth-child(1) {
    top: 18%;
    left: 8%;
  }

  .answer-card:nth-child(2) {
    top: 18%;
    right: 8%;
  }

  .answer-card:nth-child(3) {
    bottom: 18%;
    left: 8%;
  }

  .answer-card:nth-child(4) {
    bottom: 18%;
    right: 8%;
  }

  .game-title h1 {
    font-size: 1.8rem;
    margin-bottom: 4px;
  }

  .game-title p {
    font-size: 0.9rem;
    margin-top: 0;
  }

  .review-text {
    font-size: 14px;
    line-height: 1.4;
    padding: 0 16px;
    margin: 8px auto;
    max-width: 260px;
  }

  .gathering-text {
    font-size: 14px;
    line-height: 1.4;
    margin: 12px auto;
    padding: 0 16px;
    max-width: 260px;
  }

  .buttons-container {
    gap: 10px;
    margin: 16px auto;
  }

  .buy-now-button,
  .game-night-button,
  .modal-buy-button {
    height: 44px;
    font-size: 0.95rem;
    max-width: 280px;
  }

  .product-image {
    margin-top: -15px;
    margin-bottom: 0;
  }

  .review-text,
  .gathering-text {
    margin-bottom: 16px;
  }
}

.event-slideshow {
  width: 100%;
  overflow-x: scroll;
  -webkit-overflow-scrolling: touch;
  scrollbar-width: none;
  margin: 20px 0;
}

.event-slideshow-inner {
  display: flex;
  gap: 5px;
  padding: 0 15px;
  width: max-content;
  animation: none !important;
  transform: none !important;
}

.event-image {
  flex: 0 0 auto;
  width: 180px;
  height: auto;
  object-fit: cover;
  border-radius: 8px;
}

.product-image {
  margin-top: -50px;
  margin-bottom: -20px;
  text-align: center;
  cursor: pointer;
  transition: transform 0.3s ease;
  padding: 0 20px;
}

.product-image img {
  max-width: 450px;
  width: 100%;
  height: auto;
  object-fit: contain;
}

@media (max-width: 768px) {
  .product-image {
    margin-top: -30px;
    margin-bottom: -10px;
    padding: 0 16px;
  }
  
  .product-image img {
    max-width: 320px;
  }
}

@media (max-width: 380px) {
  .product-image {
    margin-top: -20px;
    margin-bottom: -5px;
    padding: 0 12px;
  }
  
  .product-image img {
    max-width: 260px;
  }
}

.product-image:hover {
  transform: scale(1.05);
}

.product-image:active {
  transform: scale(0.98);
}

/* Enhanced styling for top buttons */
.buttons-container {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 15px;
  width: 90%;
  max-width: 600px;
  margin: 5vh auto 0;
}

/* Brands container styling */
.brands-container {
  background-color: rgba(0, 0, 0, 0.7);
  color: #fff;
  padding: 15px 15px;
  border-radius: 12px;
  text-align: center;
  margin: 35vh auto 0;
  width: 90%;
  max-width: 500px;
}

.brands-container h2 {
  font-size: 1rem;
  font-weight: normal;
  margin-bottom: 10px;
  line-height: 1.3;
}

.brand-logo {
  height: 25px;
  width: auto;
  object-fit: contain;
  opacity: 0.8;
  transition: opacity 0.3s ease;
}

@media (max-width: 768px) {
  .buttons-container {
    width: calc(100% - 32px);
    margin: 5vh auto 0;
    gap: 10px;
  }

  .buttons-container:first-of-type {
    margin-top: 5vh;
  }

  .buy-now-button,
  .game-night-button,
  .modal-buy-button {
    width: 45%;
    max-width: 200px;
    height: auto;
    font-size: 0.9rem;
    padding: 8px 16px;
    border-radius: 8px !important;
    background-color: rgba(0, 0, 0, 0.85) !important;
    color: #ffffff !important;
    border: none !important;
  }
  
  .brands-container {
    margin-top: 25vh;
    padding: 12px;
  }
  
  .brand-logo {
    height: 22px;
  }
}

@media (max-width: 480px) {
  .buttons-container {
    flex-direction: row;
    gap: 8px;
  }
  
  .buy-now-button,
  .game-night-button {
    width: 45%;
    font-size: 0.8rem;
    padding: 8px 12px;
  }
  
  .buy-now-button span {
    margin-right: 5px;
  }
  
  .buy-now-button img {
    height: 20px;
  }
  
  .brands-container {
    margin-top: 20vh;
    padding: 10px;
  }
  
  .brand-logo {
    height: 20px;
  }
}

.laurel-container {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 20px auto;
  max-width: 800px;
  padding: 0 20px;
  position: relative;
}

.laurel {
  width: 60px;
  height: 120px;
}

.laurel.left {
  margin-right: 20px;
}

.laurel.right {
  margin-left: 20px;
}

.review-container {
  flex: 1;
  text-align: center;
  max-width: 600px;
  margin: 0 auto;
  background: transparent;
}

.review-text {
  font-size: 24px;
  line-height: 1.4;
  margin: 0 auto;
  padding: 0 20px;
  font-family: -apple-system, BlinkMacSystemFont, 'Helvetica Neue', sans-serif;
  max-width: 600px;
  position: relative;
  transition: all 0.8s cubic-bezier(0.4, 0.0, 0.2, 1);
  background: transparent;
  opacity: 1;
  will-change: transform, opacity;
  transform: translateZ(0);
  backface-visibility: hidden;
  -webkit-font-smoothing: subpixel-antialiased;
}

.review-text.changing {
  opacity: 0;
  transform: translateY(5px);
  pointer-events: none;
}

.gathering-text {
  font-size: 24px;
  line-height: 1.4;
  max-width: 600px;
  margin: 32px auto;
  padding: 0 24px;
  text-align: center;
  font-family: -apple-system, BlinkMacSystemFont, 'Helvetica Neue', sans-serif;
  color: #000;
}

.brands-section {
  width: 100%;
  padding: 20px 0;
  overflow: hidden;
}

.brands-slider {
  width: 100%;
  padding: 20px 0;
  display: flex;
  justify-content: center;
}

.brands-container {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 100px;
  padding: 0 50px;
  flex-wrap: wrap;
  max-width: 1200px;
  margin: 0 auto;
}

.brand-logo {
  height: 40px;
  width: auto;
  object-fit: contain;
  opacity: 0.8;
  transition: opacity 0.3s ease;
}

.brand-logo:hover {
  opacity: 1;
}

@media (max-width: 768px) {
  .brands-container {
    gap: 80px;
    padding: 0 40px;
  }
  .brand-logo {
    height: 30px;
  }
}

@media (max-width: 480px) {
  .brands-container {
    gap: 60px;
    padding: 0 30px;
  }
  .brand-logo {
    height: 25px;
  }
}

.event-gallery {
  display: flex;
  gap: 10px;
  padding: 20px;
  overflow-x: auto;
  scroll-snap-type: x mandatory;
  -webkit-overflow-scrolling: touch;
  scrollbar-width: none;
  -ms-overflow-style: none;
}

.event-gallery::-webkit-scrollbar {
  display: none;
}

.event-image {
  flex: 0 0 auto;
  width: 180px;
  height: 240px;
  object-fit: cover;
  border-radius: 8px;
  scroll-snap-align: start;
}

.copyright {
  margin: 20px 0;
  font-size: 14px;
  color: #000000;
  font-family: -apple-system, BlinkMacSystemFont, 'Helvetica Neue', sans-serif;
}

@media (max-width: 768px) {
  .laurel {
    width: 40px;
    height: 80px;
  }

  .review-text {
    font-size: 20px;
  }

  .gathering-text {
    font-size: 20px;
    margin: 30px auto;
  }

  .brand-logo {
    height: 30px;
  }

  .event-image {
    width: 140px;
    height: 200px;
  }
}

@media (max-width: 480px) {
  .laurel {
    width: 30px;
    height: 60px;
  }

  .review-text {
    font-size: 18px;
  }

  .gathering-text {
    font-size: 18px;
    margin: 20px auto;
  }

  .brand-logo {
    height: 25px;
  }

  .event-image {
    width: 120px;
    height: 180px;
  }
}

@media screen and (max-width: 768px) {
  .App {
    background-attachment: scroll;
    background-size: 100% auto;
    background-position: top center;
  }
  
  /* Add padding for embeds on mobile */
  iframe[src*="spotify"],
  iframe[src*="instagram"] {
    margin: 0 20px !important;
    width: calc(100% - 40px) !important;
    border-radius: 12px !important;
  }
}

@media screen and (max-width: 480px) {
  .App {
    background-size: 200% auto;
    background-position: top center;
  }
  
  /* Adjust padding for smaller screens */
  iframe[src*="spotify"],
  iframe[src*="instagram"] {
    margin: 0 15px !important;
    width: calc(100% - 30px) !important;
  }
}