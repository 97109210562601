.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.65);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  padding: 10px;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  backdrop-filter: blur(5px);
  opacity: 1;
  transition: opacity 0.4s ease-out, backdrop-filter 0.4s ease-out;
  animation: fadeIn 0.4s ease-out forwards;
}

.modal-content {
  background: linear-gradient(145deg, #000000, #111111);
  padding: 0;
  border-radius: 24px;
  width: 90%;
  max-width: 420px;
  position: relative;
  box-shadow: 0 20px 40px rgba(0, 0, 0, 0.2), 0 10px 20px rgba(0, 0, 0, 0.1);
  margin: auto;
  overflow: hidden;
  max-height: calc(100vh - 60px);
  display: flex;
  flex-direction: column;
  animation: slideIn 0.5s cubic-bezier(0.175, 0.885, 0.32, 1.275) forwards;
}

.modal-close {
  position: absolute;
  top: 1rem;
  right: 1rem;
  background: rgba(255, 255, 255, 0.9);
  border: none;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.8rem;
  cursor: pointer;
  color: #333;
  line-height: 1;
  transition: all 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  z-index: 10;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
}

.modal-close:hover {
  color: #000;
  background: rgba(255, 255, 255, 1);
  transform: rotate(90deg) scale(1.1);
  box-shadow: 0 6px 15px rgba(0, 0, 0, 0.2);
}

.modal-body {
  padding: 0 0 1rem 0;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  flex: 1;
}

.modal-header {
  padding: 0 0 0.5rem 0;
  text-align: center;
  position: relative;
}

.modal-product-image {
  width: 100%;
  max-width: 180px;
  margin: 1.5rem auto 0.5rem;
  filter: drop-shadow(0 10px 20px rgba(0, 0, 0, 0.15));
  transition: all 0.5s ease;
}

.modal-title {
  margin: 10px 0 20px;
  font-size: 2.2rem;
  color: white;
  text-align: center;
  line-height: 1.2;
  font-weight: 600;
  letter-spacing: -0.5px;
  padding: 0 2rem;
}

.modal-subtitle {
  margin: 0 0 0.5rem;
  color: #555;
  text-align: center;
  font-size: 1.1rem;
  line-height: 1.5;
  padding: 0 2rem;
  letter-spacing: 0.2px;
  font-weight: 400;
}

.modal-details {
  padding: 0 1.5rem 1rem;
}

.compact-card-list {
  display: flex;
  flex-direction: column;
  gap: 6px;
  width: 100%;
  margin: 0.75rem 0;
}

.card-item {
  font-size: 1.25rem;
  padding: 1rem 1.3rem;
  background: rgba(0, 0, 0, 0.85);
  border-radius: 12px;
  text-align: left;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.05);
  transition: all 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  border: 1px solid rgba(255, 255, 255, 0.1);
  color: white;
  margin-bottom: 10px;
  font-weight: 500;
  letter-spacing: 0.3px;
}

.card-item:hover {
  transform: translateY(-3px);
  background: rgba(0, 0, 0, 0.95);
  box-shadow: 0 6px 15px rgba(0, 0, 0, 0.15);
  border: 1px solid rgba(255, 255, 255, 0.2);
}

.description {
  font-size: 1rem;
  line-height: 1.5;
  margin: 1.5rem 0;
  color: #555;
  text-align: center;
}

.modal-buy-button {
  width: 100%;
  padding: 1.2rem 1.5rem;
  background: linear-gradient(135deg, #000000, #333333);
  color: white;
  border: none;
  border-radius: 30px;
  font-size: 1.3rem;
  font-weight: 500;
  letter-spacing: 0.5px;
  cursor: pointer;
  transition: all 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  margin-top: 1.5rem;
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.15);
  position: relative;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}

.button-text {
  margin-right: 10px;
  position: relative;
  z-index: 2;
  transition: all 0.3s ease;
}

.button-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  z-index: 2;
  transition: all 0.3s ease;
}

.modal-buy-button:hover {
  transform: translateY(-3px);
  box-shadow: 0 12px 25px rgba(0, 0, 0, 0.2);
}

.modal-buy-button:hover .button-text {
  transform: translateX(-3px);
}

.modal-buy-button:hover .button-icon {
  transform: translateX(3px);
}

.modal-buy-button::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(to right, rgba(255,255,255,0) 0%, rgba(255,255,255,0.1) 50%, rgba(255,255,255,0) 100%);
  transform: translateX(-100%);
  transition: transform 0.6s ease-out;
}

.modal-buy-button:hover::after {
  transform: translateX(100%);
}

@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}

@keyframes slideIn {
  from { 
    opacity: 0;
    transform: translateY(30px) scale(0.95);
  }
  to { 
    opacity: 1;
    transform: translateY(0) scale(1);
  }
}

@media (max-width: 480px) {
  .modal-content {
    width: 95%;
    margin: 50px auto;
    max-height: calc(100vh - 100px);
  }

  .modal-title {
    font-size: 1.6rem;
    padding: 0 1rem;
  }

  .card-item {
    font-size: 0.9rem;
    padding: 0.6rem 0.8rem;
  }

  .modal-product-image {
    max-width: 150px;
    margin: 1rem auto 0.5rem;
  }

  .description {
    font-size: 0.95rem;
    margin: 1.2rem 0;
  }

  .modal-buy-button {
    padding: 0.9rem 1.25rem;
    font-size: 1rem;
  }

  .modal-close {
    top: 0.75rem;
    right: 0.75rem;
    width: 36px;
    height: 36px;
    font-size: 1.5rem;
  }
} 