body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.game-title {
  text-align: center;
  margin: 20px 0;
}

.logo-image {
  max-width: 120px;
  height: auto;
  display: block;
  margin: -5px auto 0;
  position: relative;
  top: -20px;
}

.footer {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  padding: 20px 0;
}

.app-store-container {
  margin-bottom: 15px;
}

.instructions-button {
  margin: 15px 0;
}

.social-links {
  margin: 15px 0;
  text-align: center;
}

.app-store-badge {
  display: block;
  transition: all 0.2s ease;
}

.app-store-badge:hover {
  transform: scale(1.05);
}

.instagram-link {
  color: #FF69B4;
  font-size: 32px;
  transition: transform 0.2s ease;
  display: inline-block;
}

.instagram-link:hover {
  transform: scale(1.1);
}

.fab.fa-instagram {
  display: inline-block;
}

.App {
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin: 0;
  overflow-x: hidden;
}

.event-gallery {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
  justify-content: center;
  gap: 20px;
}

.event-image {
  width: calc(50% - 10px);
  height: auto;
  display: block;
  border-radius: 12px;
}

/* Mobile adjustments */
@media screen and (max-width: 768px) {
  .event-gallery {
    gap: 10px; /* Reduce gap on mobile */
    padding: 0 10px;
  }
  
  .event-image {
    width: calc(50% - 5px); /* Make images smaller and 2 per row */
    height: 150px; /* Fixed height for consistent grid */
    object-fit: cover; /* Maintain aspect ratio while filling space */
  }
}

.game-explanation {
  position: relative;
  width: 100%;
  max-width: 800px;
  margin: 0px auto 0;
  padding: 0 20px;
  text-align: left;
}

.floating-symbols-container {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  pointer-events: none;
  z-index: 1;
}

.floating-symbol {
  position: absolute;
  font-size: 24px;
  opacity: 0.6;
  pointer-events: none;
  animation: floatSmooth infinite ease-in-out;
  will-change: transform;
}

@keyframes floatSmooth {
  0% {
    transform: translateY(0) rotate(0deg);
  }
  50% {
    transform: translateY(-20px) rotate(5deg);
  }
  100% {
    transform: translateY(0) rotate(0deg);
  }
}

.game-explanation h2, .game-rules {
  font-family: -apple-system, BlinkMacSystemFont, 'Helvetica Neue', sans-serif;
  font-size: 32px;
  line-height: 1.3;
  font-weight: 400;
  max-width: 800px;
  margin: 40px auto;
  padding: 0 20px;
  text-align: left;
}

.game-title-text {
  display: inline;
  font-weight: 700;
}

.subtitle-text {
  display: inline;
  font-weight: 400;
  font-style: normal;
  color: inherit;
}

.section-header {
  font-size: 32px;
  font-weight: 400;
  color: inherit;
}

.game-steps {
  text-align: left;
  padding: 0;
  margin: 5px 0;
  width: 100%;
}

.step-item, .step-description {
  font-size: 32px;
  font-weight: 400;
  color: inherit;
}

.closing-text {
  font-size: 22px;
  font-weight: 600;
  font-style: italic;
  color: #FF69B4;
  margin-top: 5px;
  line-height: 1.1;
}

button.buy-now-button {
  background-color: #000 !important;
  border: none !important;
  border-radius: 30px !important;
  padding: 15px 50px !important;
  margin: 30px auto;
  transform: scale(1);
  box-shadow: none;
}

button.buy-now-button .button-text {
  color: #FFF !important;
  font-size: 24px;
  letter-spacing: 1px;
}

button.buy-now-button:hover {
  transform: scale(1.05);
  opacity: 0.9;
}

/* Remove animation */
.buy-now-button {
  animation: none;
}

/* Add responsive adjustments for very small screens */
@media screen and (max-width: 380px) {
  .game-title-text {
    font-size: 22px;
  }
  
  .subtitle-text {
    font-size: 16px;
  }
  
  .step-item {
    font-size: 18px;
  }
  
  .step-description {
    font-size: 14px;
  }
  
  .game-explanation {
    width: 90%;
  }
}

/* Remove any potential browser default styles */
* {
  box-sizing: border-box;
}

/* Remove extra spacing */
/* br {
  display: none;
} */

/* Adjust spacing between sections */
.game-explanation h2 > * {
  margin: 5px 0;
}

.game-contents {
  margin: 30px 0 20px;
  text-align: left;
}

.content-item {
  font-size: 32px; /* Match other text size */
  font-weight: 500;
  line-height: 1.1;
  margin: 5px 0;
  color: #000000;
}

/* Adjust spacing for buy button after contents */
.buy-now-button {
  margin: 20px auto 40px;
}

/* Hamburger Menu Icon */
.menu-icon {
  position: fixed;
  top: 20px;
  right: 20px;
  width: 30px;
  height: 24px;
  cursor: pointer;
  z-index: 2000; /* Higher z-index to stay above overlay */
  background: transparent;
  border: none;
  padding: 10px;
  margin: 0;
  outline: none;
  border-radius: 50%;
  transition: all 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);
}

/* Three lines */
.menu-icon::before,
.menu-icon::after,
.menu-icon .middle-line {
  content: '';
  position: absolute;
  width: 30px;
  height: 2px;
  background: #fff;
  left: 0;
  border-radius: 5px;
  transition: all 0.4s cubic-bezier(0.68, -0.6, 0.32, 1.6);
}

.menu-icon::before {
  top: 0;
  width: 20px;
  right: 0;
  left: auto;
}

.menu-icon .middle-line {
  top: 50%;
  transform: translateY(-50%);
  width: 30px;
}

.menu-icon::after {
  bottom: 0;
  width: 25px;
  right: 0;
  left: auto;
}

.menu-icon:hover::before,
.menu-icon:hover::after,
.menu-icon:hover .middle-line {
  width: 30px;
  background: #fff;
}

.menu-icon:hover {
  transform: scale(1.05);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

/* Full screen overlay */
.menu-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 228, 225, 0.95); /* Light pink background with transparency */
  opacity: 0;
  visibility: hidden;
  transition: all 0.5s cubic-bezier(0.19, 1, 0.22, 1);
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;
  pointer-events: none; /* Add this to prevent overlay from blocking clicks */
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
}

.menu-overlay.open {
  opacity: 1;
  visibility: visible;
  pointer-events: auto; /* Enable pointer events when menu is open */
}

.menu-overlay.open .menu-items {
  transform: translateY(0);
  opacity: 1;
}

/* Menu Items Container */
.menu-items {
  text-align: center;
  font-family: -apple-system, BlinkMacSystemFont, 'Helvetica Neue', sans-serif;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 18px;
  position: relative;
  z-index: 1001; /* Higher than the menu-overlay z-index */
  pointer-events: auto; /* Ensure pointer events are enabled */
  padding: 50px 30px;
  max-width: 90%;
  width: 400px;
  background: rgba(255, 255, 255, 0.8);
  border-radius: 20px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.08);
  transform: translateY(20px);
  opacity: 0;
  transition: all 0.5s cubic-bezier(0.19, 1, 0.22, 1);
  border: 1px solid rgba(0, 0, 0, 0.05);
}

.menu-item {
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: -apple-system, BlinkMacSystemFont, 'Helvetica Neue', sans-serif;
  font-size: 1.1rem;
  font-weight: 500;
  color: #000000;
  text-decoration: none;
  margin: 8px 0;
  padding: 0 30px;
  height: 56px;
  width: 100%;
  max-width: 320px;
  border-radius: 28px;
  position: relative;
  transition: all 0.4s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  cursor: pointer;
  background: linear-gradient(to right, #FFF5EA, #FFF0DB) !important;
  color: #000000 !important;
  border: 1px solid rgba(0, 0, 0, 0.15) !important;
  letter-spacing: 1px;
  white-space: nowrap;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.04);
  overflow: hidden;
}

.menu-item::after {
  content: '→';
  position: absolute;
  right: 25px;
  opacity: 0;
  transition: all 0.4s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  font-size: 1.2rem;
}

.menu-item::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: radial-gradient(circle, rgba(255,255,255,0.8) 0%, rgba(255,255,255,0) 70%);
  opacity: 0;
  transform: scale(0.5);
  transition: all 0.5s ease;
  z-index: 0;
}

.menu-item:hover::after {
  opacity: 1;
  right: 20px;
}

.menu-item:hover::before {
  opacity: 1;
  transform: scale(2);
}

.menu-item:hover {
  transform: translateY(-3px) scale(1.02);
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.08);
  background: linear-gradient(to right, #FFF0DB, #FFE4D0) !important;
  color: #000000 !important;
  padding-right: 45px;
  border-color: rgba(0, 0, 0, 0.2) !important;
}

.menu-item:active {
  transform: translateY(1px);
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

/* Hamburger to X animation */
.menu-icon.open {
  box-shadow: 0 0 0 rgba(0, 0, 0, 0);
  background-color: rgba(255, 255, 255, 0.2);
}

.menu-icon.open::before {
  transform: translateY(9px) rotate(45deg);
  background: #fff; /* Ensure visibility on cream background */
  width: 30px;
  left: 0;
}

.menu-icon.open::after {
  transform: translateY(-9px) rotate(-45deg);
  background: #fff;
  width: 30px;
  left: 0;
}

.menu-icon.open .middle-line {
  opacity: 0;
  transform: translateX(-20px);
}

.laurel-container {
  margin: 40px auto;
  max-width: 800px;
  padding: 20px;
}

.review-container {
  min-height: 80px; /* Ensure consistent height during transitions */
}

.app-explanation h2 {
  font-family: -apple-system, BlinkMacSystemFont, 'Helvetica Neue', sans-serif;
  font-size: 32px;
  line-height: 1.3;
  font-weight: 400;
  max-width: 800px;
  margin: 40px auto;
  padding: 0 20px;
  text-align: left;
}

.menu-title {
  font-size: 38px;
  font-weight: 600;
  margin-bottom: 30px;
  color: #000;
  letter-spacing: 1px;
  text-transform: lowercase;
  position: relative;
  text-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
}

.menu-title:after {
  content: '';
  position: absolute;
  bottom: -12px;
  left: 50%;
  transform: translateX(-50%);
  width: 60px;
  height: 3px;
  background: linear-gradient(to right, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.1));
  border-radius: 3px;
}

/* Add animation for menu items */
.menu-items .menu-item,
.menu-items .menu-section-title,
.menu-items .menu-description {
  animation: slideInMenuItem 0.5s cubic-bezier(0.175, 0.885, 0.32, 1.275) both;
  animation-delay: calc(0.1s * var(--item-index, 0));
}

.menu-section-title {
  font-size: 24px;
  font-weight: 600;
  margin-top: 20px;
  margin-bottom: 5px;
  font-family: -apple-system, BlinkMacSystemFont, 'Helvetica Neue', sans-serif;
  color: #000;
  position: relative;
  padding-bottom: 8px;
}

.menu-section-title:after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 40px;
  height: 2px;
  background: linear-gradient(to right, rgba(0, 0, 0, 0.05), rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.05));
  border-radius: 2px;
}

.menu-description {
  font-size: 18px;
  line-height: 1.5;
  max-width: 320px;
  padding: 0 10px;
  text-align: center;
  font-family: -apple-system, BlinkMacSystemFont, 'Helvetica Neue', sans-serif;
  color: #333;
  margin-top: 5px;
}

@keyframes slideInMenuItem {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Mobile adjustments for menu */
@media screen and (max-width: 480px) {
  .menu-items {
    width: 90%;
    padding: 25px 15px;
  }
  
  .menu-item {
    font-size: 24px;
    padding: 10px 20px;
    min-width: 180px;
  }
}

/* Hide menu title */
.menu-title {
  display: none !important;
}
